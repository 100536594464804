const getUniqueData = (data: []) => {
  return data?.length > 0
    ? Array.from(
        data
          ?.reduce((map: any, obj: any) => {
            const key = `${obj.frmState}_${obj.toState}_${obj.frmTerminal}_${obj.toTerminal}_${obj.company}_${obj.vehicleName}`;
            if (!map.has(key)) map.set(key, obj);
            return map;
          }, new Map())
          .values()
      )
    : [];
};

export default getUniqueData;