import { useEffect } from "react";
import BusFaq from "./bus-faq";
import BusResultsContainer from "./bus-results-container";
import { Element, Events, scroller } from "react-scroll";
import { useLocation } from "react-router";
import ReactGA from 'react-ga4';
import BusFooter from "./bus-footer";

const Buses = () => {
  const location = useLocation();

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname+window.location.search });
    }, []);

  const state = location.state as { name: string };
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    if (state?.name) {
      scroller.scrollTo(state?.name, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
    // eslint-disable-next-line
  }, [{}, state?.name]);

  return (
    <div>
      <BusResultsContainer />
      <Element name="faq">
        <BusFaq />
      </Element>
      <BusFooter />
    </div>
  );
};

export default Buses;
