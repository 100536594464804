import { Box, HStack, Text, Image, VStack } from "@chakra-ui/react";
import HappyRiders from "../../assets/Happy-riders.png";
import HappyFace from "../../assets/Happy-face.png";

export default function BusHowItWorks() {
  return (
    <Box
      paddingY={{ base: 28, lg: 40 }}
      margin={{ base: "auto"}}
      maxW={{ base: "fit-content", lg: "100%" }}
    >
      <HStack
        maxW={{ base: "100%", lg: "62%" }}
        margin="auto"
        justifyContent="space-between"
        display={{ base: "inline-grid", lg: "flex" }}
      >
        <Box order={{ base: 2, lg: 1 }}>
          <Text
            mb={8}
            textTransform="uppercase"
            fontWeight={600}
            color="#5E6282"
          >
            How It Works
          </Text>
          <Text
            as="h3"
            fontSize={{ base: 22, lg: 32 }}
            fontWeight="bold"
            lineHeight={{ base: "36px", lg: "40px" }}
            letterSpacing="-0.02em"
            marginBottom={8}
            // width={{ base: 380, lg: 420}}
          >
            Book your next trip in 3 easy steps
          </Text>
          <HStack gap={{ base: 4, lg: 8 }} alignItems="flex-end" mb={12}>
            <Box
              bgColor="#B3FB58"
              width={55}
              minHeight={50}
              borderRadius={18}
              border="1px solid #000000"
              mt={10}
            >
              <VStack padding={2}>
                <Text fontWeight={900} fontSize={16} textTransform="uppercase">
                  Step
                </Text>
                <Text
                  fontWeight={900}
                  fontSize={16}
                  marginTop={" 0 !important"}
                  lineHeight={"15px"}
                >
                  1
                </Text>
              </VStack>
            </Box>
            <Box>
              <Text color="#5E6282" fontWeight="bold">
                Choose Destination
              </Text>
              <Text width={{ base: 260, lg: 300 }}>
                Select the state you are travelling from and the state you are
                travelling to and apply different filters to the search result.
              </Text>
            </Box>
          </HStack>
          <HStack gap={{ base: 4, lg: 8 }} alignItems="flex-start" mb={12}>
            <Box>
              <Text color="#5E6282" fontWeight="bold">
                Initiate Booking
              </Text>
              <Text width={{ base: 260, lg: 300 }}>
                Once you find a ticket that suits you, initiate booking by clicking the
                "visit site" button and proceed to the bus operators website.
              </Text>
            </Box>
            <Box
              bgColor="#B3FB58"
              width={55}
              minHeight={50}
              borderRadius={18}
              border="1px solid #000000"
              mt={10}
            >
              <VStack padding={2}>
                <Text fontWeight={900} fontSize={16} textTransform="uppercase">
                  Step
                </Text>
                <Text
                  fontWeight={900}
                  fontSize={16}
                  marginTop={" 0 !important"}
                  lineHeight={"15px"}
                >
                  2
                </Text>
              </VStack>
            </Box>
          </HStack>
          <HStack gap={{ base: 4, lg: 8 }} alignItems="flex-start">
            <Box
              bgColor="#B3FB58"
              width={55}
              minHeight={50}
              borderRadius={18}
              border="1px solid #000000"
            >
              <VStack padding={2}>
                <Text fontWeight={900} fontSize={16} textTransform="uppercase">
                  Step
                </Text>
                <Text
                  fontWeight={900}
                  fontSize={16}
                  marginTop={" 0 !important"}
                  lineHeight={"15px"}
                >
                  3
                </Text>
              </VStack>
            </Box>
            <Box>
              <Text color="#5E6282" fontWeight="bold">
                Finalize Booking
              </Text>
              <Text width={{ base: 260, lg: 300 }}>
                Once you are at the bus operators website. search for the ticket you would like to book and complete booking on their website!
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box
          position="relative"
          order={{ base: 1, lg: 2 }}
          display={{ base: "flex", lg: "block" }}
          justifyContent="center"
          marginLeft={"0px !important"}
        >
          <Image
            src={HappyRiders}
            alt="happy riders"
            width={350}
            mb={{ base: 8, lg: 0 }}
          />
          <Image
            src={HappyFace}
            alt="happy riders"
            position="absolute"
            bottom={0}
            right={0}
            display={{ base: "none", lg: "initial" }}
          />
        </Box>
      </HStack>
    </Box>
  );
}
