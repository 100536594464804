import { useState, useEffect } from "react";
import { Box, Button, HStack } from "@chakra-ui/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import React from "react";
import { addDays } from "date-fns";
import { states } from "../../utils/states";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@chakra-ui/react";
import ReactGA from "react-ga4";

const options = [...states];

type Option = {
  value: string;
  label: string;
};

type Props = {
  frmState?: string | null;
  toState?: string | null;
  passedDate?: string | null;
};
export default function BusSearch({ frmState, toState, passedDate }: Props) {
  const [isLargerThan625] = useMediaQuery("(min-width: 625px)");
  const [selectedOption, setSelectedOption] = useState<null | Option>(
    frmState ? { value: frmState, label: frmState } : null
  );
  const [selectedOption2, setSelectedOption2] = useState<null | Option>(
    toState ? { value: toState, label: toState } : null
  );

  //covert dd/mm/yy to yy-mm-dd, whcih is the format that cdan be passed to new Date()
  let [day, month, year]: string[] = passedDate ? passedDate.split("/") : [];
  const [date, setDate] = useState<Date>(
    passedDate ? new Date(+year, +month - 1, +day) : addDays(new Date(), 1)
  );

  useEffect(() => {
    if(frmState) setSelectedOption({ value: frmState, label: frmState } )
    if(toState) setSelectedOption2({ value: toState, label: toState } )
  }, [frmState, toState])
  
  const navigate = useNavigate();
  const goToBuses = () => {
    ReactGA.event({
      category: "search",
      action: "Click",
      label: `frmState=${selectedOption?.value}&toState=${selectedOption2?.value}`,
    });
    navigate({
      pathname: "/buses",
      search: `?frmState=${selectedOption?.value || ""}&toState=${
        selectedOption2?.value || ""
      }&date=${date.toLocaleDateString("en-GB")}`,
    });
  };

  const CustomInput = React.forwardRef<any, any>(({ value, onClick }, ref) => (
    <Box
      onClick={onClick}
      ref={ref as any}
      border={{ base: "2px solid #F4F4F4", md: "none" }}
      padding={{ base: "16px 8px", md: "none" }}
    >
      {value || "Departure"}
    </Box>
  ));

  return (
    <HStack
      width={{ base: "90%", lg: 800 }}
      height={{ base: "100%", md: 70 }}
      border={{ base: "none", md: "2px solid #F4F4F4" }}
      borderLeft={{ base: "none", md: "none" }}
      borderRadius={18}
      paddingRight={2}
      display={{ base: "block", md: "flex" }}
    >
      <HStack
        flex={1}
        height="inherit"
        fontWeight="bold"
        display={{ base: "block", md: "flex" }}
        fontSize={14}
        marginBottom={{ base: 2, md: 0 }}
      >
        {/* <Box ml={2} flex={1} textAlign="left">From</Box> */}
        <Select
          value={selectedOption}
          onChange={setSelectedOption}
          options={options}
          placeholder="From"
          styles={{
            container: (styles) => ({
              ...styles,
              height: "inherit",
              flex: 1,
              ...(!isLargerThan625 && { height: "57px" }),
            }),
            control: (styles) => ({
              ...styles,
              height: "inherit",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: "2px solid #F4F4F4",
              ...(!isLargerThan625 && { borderBottom: "none" }),
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: "none",
              fontSize: "14px",
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              display: "none",
            }),
            placeholder: (styles) => ({
              ...styles,
              color: "black",
            }),
          }}
        />
        <Select
          value={selectedOption2}
          onChange={setSelectedOption2}
          options={options}
          placeholder="To"
          styles={{
            container: (styles) => ({
              ...styles,
              height: "inherit",
              flex: 1,
              marginLeft: "0px !important",
              ...(!isLargerThan625 && { height: "57px" }),
            }),
            control: (styles) => ({
              ...styles,
              height: "inherit",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: "2px solid #F4F4F4",
              ...(isLargerThan625 && { borderLeft: "none" }),
              ...(!isLargerThan625 && { borderBottom: "none" }),
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: "none",
              fontSize: "14px",
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              display: "none",
            }),
            placeholder: (styles) => ({
              ...styles,
              color: "black",
            }),
          }}
        />
        <Box ml={{ base: "0 !important", md: 2 }} flex={1} textAlign="left">
          {" "}
          <DatePicker
            selected={date}
            onChange={(date: Date) => setDate(date)}
            customInput={<CustomInput />}
            placeholderText="Departure"
            minDate={addDays(new Date(), 1)}
            maxDate={addDays(new Date(), 4)}
            dateFormat="dd/MM/yyyy"
          />
        </Box>
      </HStack>

      <Button
        bgColor="#6936F5"
        borderRadius={10}
        width={{ base: "100%", md: 90, lg: 150 }}
        marginLeft="0 !important"
        height={16}
        textColor="white"
        onClick={goToBuses}
      >
        Search{" "}
      </Button>
    </HStack>
  );
}
