import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import ReactSelect from "../ReactSelect";
import { validatePhoneNumber } from "../../utils/phoneNumberValidation";
import {
       AdultbookingNumbers,
       ChildrenbookingNumbers,
     } from "../../utils/numbers";

import {
       Input,
       FormControl,
       FormLabel,
       FormErrorMessage,
       Center,
       Icon,
       Button,
       Box,
       Text
     } from "@chakra-ui/react";
import CustomModal from '../CustomModal';
import { useMutation } from 'react-query';

type Props = {
       isOpen: boolean;
       busId: string | null;
       setBusId: (param: string | null) => void;
       date: string;
       onClose: () => void;
}

export default function BusBookingForm({isOpen, busId, setBusId, date, onClose} : Props) {
       const [isBooking, setIsBookingComplete] = useState<boolean | null>(false);

       const {
              handleSubmit,
              register,
              formState: { errors },
              reset,
              control,
            } = useForm({
              defaultValues: {
                phoneNumber: "",
                email: "",
                noOfAdult: { value: 1, label: 1 },
                noOfChildren: { value: 0, label: 0 },
              },
            });

            const handleClose = () => {
              setBusId(null);
              setIsBookingComplete(null);
              onClose();
            };
          
            const onSubmit = async (data: any) => {
              try {
                const response = await fetch(
                  "https://interride-backend.herokuapp.com/api/v1/manual-scheduling",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      busScheduleId: busId,
                      noOfAdult: data.noOfAdult.value,
                      noOfChildren: data.noOfChildren.value,
                      phoneNumber: data.phoneNumber,
                      ...(data.email && { email: data.email }),
                      bookingDate: date
                    }),
                  }
                );
          
                if (!response.ok) {
                  throw new Error("An error occurred while submitting the form");
                }
                setIsBookingComplete(true);
                // Reset the form after submission
                reset();
              } catch (error) {
                console.error(error);
              }
            };
          
            const mutation = useMutation(onSubmit);
          
            const handleFormSubmit = (data: any) => {
              mutation.mutate(data);
            };

  return (
       <CustomModal
       modalTitle="Book Vehicle"
       isOpen={isOpen}
       onClose={handleClose}
       modalFooter={
         !isBooking && (
           <>
             <Button colorScheme="red" mr={3} onClick={onClose}>
               Close
             </Button>
             <Button
               type="submit"
               variant="ghost"
               onClick={() => handleSubmit(handleFormSubmit)()}
               isLoading={mutation.isLoading}
             >
               submit
             </Button>
           </>
         )
       }
     >
       {isBooking ? (
         <Center justifyContent="center">
           <Box>
             <Box margin="auto" width="fit-content">
               <Icon
                 as={IoMdCheckmarkCircleOutline}
                 boxSize={12}
                 color="#6936F5"
               />
             </Box>

             <Text>
               Thank you for Booking with us, we will reach out to you shortly (on Whatsappp or call) to
               finalize your trip arrangement
             </Text>
           </Box>
         </Center>
       ) : (
         <form>
           <FormControl isInvalid={!!errors.phoneNumber} mb={4}>
             <FormLabel
               fontSize={10}
               fontWeight={600}
               textTransform="uppercase"
               letterSpacing="1px"
               mr={2}
               width="max-content"
               mt={4}
             >
               Phone Number (prefferably Whatsapp):
             </FormLabel>
             <Input
               {...register("phoneNumber", {
                 required: "Valid Nigerian Phone number is required",
                 validate: validatePhoneNumber,
               })}
               placeholder='e.g +234[7/8/9].... or 0[7/8/9]0...'
             />
             <FormErrorMessage>
               {errors.phoneNumber && errors.phoneNumber.message?.toString()}
             </FormErrorMessage>
           </FormControl>

           <Box>
             <SelectLabel mt={4}>Email (optional):</SelectLabel>
             <Input type="email" {...register("email")} />
           </Box>
           <Box>
             <SelectLabel mt={4}>No of Adults:</SelectLabel>
             <Controller
               name="noOfAdult"
               control={control}
               render={({ field }) => (
                 <ReactSelect
                   options={AdultbookingNumbers}
                   placeholder=""
                   setSelectedOption={() => ({})}
                   {...field}
                 />
               )}
             />
           </Box>
           <Box alignItems="center" flex={1}>
             <SelectLabel mt={4}>No of Children:</SelectLabel>
             <Controller
               name="noOfChildren"
               control={control}
               render={({ field }) => (
                 <ReactSelect
                   options={ChildrenbookingNumbers}
                   placeholder=""
                   setSelectedOption={() => ({})}
                   {...field}
                 />
               )}
             />
           </Box>
         </form>
       )}
     </CustomModal>
  )
}


const SelectLabel = ({
       children,
       mt,
     }: {
       children: React.ReactNode;
       mt?: number;
     }) => {
       return (
         <Text
           fontSize={10}
           fontWeight={600}
           textTransform="uppercase"
           letterSpacing="1px"
           mr={2}
           width="max-content"
           mt={mt}
         >
           {children}
         </Text>
       );
     };