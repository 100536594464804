import { Box, VStack } from "@chakra-ui/react";
import BusSearch from "./bus-search";

export default function BusHeader() {
  return (
    <VStack minHeight={{ base: 420, lg: 550,  }}  justifyContent="center">

      <Box
        as="h1"
        fontSize={{ base: 45, lg: 55,  }}
        fontWeight="bold"
        textAlign="center"
        lineHeight={{ base: "60px", lg: "70px",  }}
        letterSpacing="-0.05em"
        maxW={{ base: 450, lg: 650,  }}
        marginBottom={42}
        marginTop={12}
      >
        {/* Find and book available bus tickets */}
        Find available bus rides for destinations in Nigeria
      </Box>
      <BusSearch />
    </VStack>
  );
}
