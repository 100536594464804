import {
  Box,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";

import { useLocation, useNavigate } from "react-router";
import { IoMdMenu } from "react-icons/io";

export default function BusNav() {
  const navigate = useNavigate();
  const l = useLocation();
  const { pathname, search } = l;
  const [isLargerThan720] = useMediaQuery("(min-width: 720px)");

  return (
    <Box
      maxWidth="85vw"
      textAlign="center"
      fontSize="xl"
      margin="auto
       "
      overflowX="hidden"
    >
      <HStack
        justifyContent="space-between"
        alignItems="flex-start"
        flex={1}
        maxWidth="85vw"
      >
        <Box
          flex={{ base: 1, lg: 2 }}
          textAlign="left"
          justifyItems="flex-start"
          color="#6936F5"
          fontWeight="bold"
          fontSize={24}
          as="h1"
          //  marginLeft={{ base: "7%", lg: "13%" }}
          marginTop={47}
          cursor="pointer"
          onClick={() => navigate({ pathname: "/" })}
        >
          Grevy.
        </Box>

        {isLargerThan720 ? (
          <HStack
            justifyContent="space-between"
            color="#171622"
            fontWeight={500}
            fontSize={16}
            lineHeight={"19px"}
            paddingTop={47}
            flex={1}
            paddingRight={{ base: 0, lg: 100 }}
            maxWidth={"400px"}
          >
            <Box
              onClick={() =>
                navigate({ pathname: "/" }, { state: { name: "how it works" } })
              }
              cursor="pointer"
            >
              How It Works
            </Box>
            <Box
              onClick={() =>
                navigate({ pathname, search }, { state: { name: "faq" } })
              }
              cursor="pointer"
            >
              FAQs
            </Box>
            <Box
              cursor="pointer"
              onClick={() =>
                window.open(
                  "https://wa.me/+2347017844062",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Help
            </Box>
          </HStack>
        ) : (
          <Box
            paddingTop={47}
            //    flex={1}
            paddingRight={{ base: 0, lg: 100 }}
          >
            <MobileMenu />
          </Box>
        )}
      </HStack>
    </Box>
  );
}

const MobileMenu = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <Menu>
      <MenuButton aria-label="Options">
        {/* <Box width='25px' height='25px'> */}
        <IconButton
          size="lg"
          aria-label="icon"
          icon={<IoMdMenu />}
          variant="outline"
        />
        {/* </Box> */}
      </MenuButton>
      <MenuList
        zIndex="2"
        cursor="pointer"
        onClick={() =>
          navigate({ pathname: "/" }, { state: { name: "how it works" } })
        }
      >
        <MenuItem>
          <Box fontSize={14}>How It Works</Box>
        </MenuItem>
        <MenuItem
          cursor="pointer"
          onClick={() =>
            navigate({ pathname, search }, { state: { name: "faq" } })
          }
        >
          <Box
            mr="4rem"
            // className="cursor-pointer"
            fontSize={14}
          >
            FAQs
          </Box>
        </MenuItem>
        <MenuItem
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://wa.me/+2347017844062",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <Box
            mr="4rem"
            // className="cursor-pointer"
            fontSize={14}
          >
            Help
          </Box>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
