export const states = [
       { label: 'Abia (Aba, Umuahia)', value: 'Abia' },
       { label: 'Adamawa', value: 'Adamawa' },
       { label: 'Akwa Ibom (Uyo)', value: 'Akwa Ibom' },
       { label: 'Anambra (Awka, Onitsha, Nnewi...)', value: 'Anambra' },
       { label: 'Bauchi', value: 'Bauchi' },
       { label: 'Bayelsa (Yenagoa)', value: 'Bayelsa' },
       { label: 'Benue', value: 'Benue' },
       { label: 'Borno', value: 'Borno' },
       { label: 'Cross River', value: 'Cross River' },
       { label: 'Delta (Asaba, Warri)', value: 'Delta' },
       { label: 'Ebonyi', value: 'Ebonyi' },
       { label: 'Edo', value: 'Edo' },
       { label: 'Ekiti', value: 'Ekiti' },
       { label: 'Enugu (Enugu, Nsukka)', value: 'Enugu' },
       { label: 'Gombe', value: 'Gombe' },
       { label: 'Imo (Owerri)', value: 'Imo' },
       { label: 'Jigawa', value: 'Jigawa' },
       { label: 'Kaduna', value: 'Kaduna' },
       { label: 'Kano', value: 'Kano' },
       { label: 'Katsina', value: 'Katsina' },
       { label: 'Kebbi', value: 'Kebbi' },
       { label: 'Kogi', value: 'Kogi' },
       { label: 'Kwara', value: 'Kwara' },
       { label: 'Lagos', value: 'Lagos' },
       { label: 'Nasarawa', value: 'Nasarawa' },
       { label: 'Niger', value: 'Niger' },
       { label: 'Ogun', value: 'Ogun' },
       { label: 'Ondo', value: 'Ondo' },
       { label: 'Osun', value: 'Osun' },
       { label: 'Oyo(Ibadan)', value: 'Oyo' },
       { label: 'Plateau (Jos)', value: 'Plateau' },
       { label: 'Rivers (Port hacourt...)', value: 'Rivers' },
       { label: 'Sokoto', value: 'Sokoto' },
       { label: 'Taraba', value: 'Taraba' },
       { label: 'Yobe', value: 'Yobe' },
       { label: 'Zamfara', value: 'Zamfara' },
       { label: 'Abuja', value: 'Abuja' }

     ];