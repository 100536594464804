import {
  Box,
  HStack,
  Text,
  Image,
  Button,
  Divider,
  VStack,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import Sienna from "../../assets/sienna.jpeg";
import Hiace from "../../assets/hiace.jpg";
import { FaArrowRight } from "react-icons/fa";
import BusFilters from "./bus-filters";
import { useState } from "react";
import BusBookingForm from "./bus-booking-form";
import { companyUrls } from "../../utils/companyUrls";
import ReactGA from "react-ga4";

export default function BusResults({
  busRoutes,
  date,
  unfilteredBusRoutes,
  setBusRoute,
}: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [busId, setBusId] = useState<string | null>(null);

  const handleOpen = (id: string) => {
    setBusId(id);
    onOpen();
  };

  return (
    <Box paddingY={30} bgColor="#F7F7F8">
      <Box display="flex" justifyContent="center" margin="auto">
        <Box width={{ base: "95%", lg: "80%" }}>
          <Box
            as="h3"
            fontSize={{ base: 28, lg: 32 }}
            fontWeight="bold"
            lineHeight={{ base: "20px", lg: "24px" }}
            letterSpacing="-0.02em"
            marginBottom={8}
          >
            Results
          </Box>
          <Box as="p" letterSpacing="-0.03em" marginBottom={8}>
            Book the most affordable bus tickets
          </Box>
          <Box fontWeight="bold">{busRoutes.length} results found</Box>
          <BusFilters
            busRoutes={busRoutes}
            unfilteredBusRoutes={unfilteredBusRoutes}
            setBusRoute={setBusRoute}
          />
          {busRoutes.map((route: any) => (
            <BusResultCard
              key={route._id}
              company={route.company}
              frmState={route.frmState}
              toState={route.toState}
              frmTerminal={route.frmTerminal}
              toTerminal={route.toTerminal}
              date={date}
              time={route.departureTime}
              fare={route.adultFare}
              vehicleName={route.vehicleName}
              vehicleUrl={route.vehicleUrl}
              vehicleType={route.vehicleType}
              onOpen={() => handleOpen(route._id)}
              fromTerminalId={route?.fromTerminalId}
              toTerminalId={route?.toTerminalId}
            />
          ))}
        </Box>
      </Box>
      <BusBookingForm
        isOpen={isOpen}
        onClose={onClose}
        busId={busId}
        setBusId={setBusId}
        date={date}
      />
    </Box>
  );
}

function BusResultCard({
  company,
  frmState,
  toState,
  frmTerminal,
  toTerminal,
  date,
  time,
  fare,
  vehicleName,
  onOpen,
  vehicleUrl,
  vehicleType,
  fromTerminalId,
  toTerminalId,
}: any) {
  const [isLargerThan720] = useMediaQuery("(min-width: 720px)");
  const companyLink =
    company?.toLowerCase() === "pmt"
      ? handlePmtTickets(fromTerminalId, toTerminalId, date)
      : companyUrls[company?.toLowerCase()];

  const handleClick = () => {
    ReactGA.event({
      category: "visitSite",
      action: "Click",
      label: `companyLink=${companyLink}&companyName=${company}`,
    });
    companyLink ? window.open(companyLink, "_blank") : onOpen();
  };
  return (
    <Box
      borderRadius={14}
      height={{ base: 52, md: 44 }}
      bgColor="white"
      paddingY={{ base: 4, lg: 8 }}
      paddingX={{ base: 4, lg: 8 }}
      mb={4}
      onClick={handleClick}
      cursor="pointer"
    >
      <HStack justifyContent="space-between" mb={2}>
        <Box display="flex" fontWeight="bold">
          {/* <Box color="#A8AABC" mr={2}>Departure</Box> */}
          <Box>
            {" "}
            {frmState}, {frmTerminal} &#8594; {toState} , {toTerminal}
          </Box>
        </Box>
        {isLargerThan720 && <Box>{vehicleName}</Box>}
      </HStack>
      <Divider />

      <HStack py={16} alignItems="center" height="100%">
        <Box width="100%">
          <HStack mb={4} justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Image
                alt="vehicle"
                src={vehicleUrl || (vehicleType === "bus" ? Hiace : Sienna)}
                // borderRadius={vehicleUrl ? "0" :"50%"}
                width={16}
                // height={16}
              />
              <Box marginLeft={2}>{company.toUpperCase()}</Box>
            </Box>
            {!isLargerThan720 && <Box>{vehicleName}</Box>}
            {isLargerThan720 && (
              <>
                <HStack>
                  <Box color="#A8AABC">{date}</Box>
                  <Box fontWeight="bold" lineHeight={"25px"}>
                    {time}
                  </Box>
                </HStack>

                <VStack>
                  <Text color="#5E6282" fontWeight="bold">
                    {fare.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    })}
                  </Text>
                  <Button
                    variant="link"
                    textColor="#6936F5"
                    rightIcon={<FaArrowRight />}
                    onClick={handleClick}
                  >
                    {companyLink ? "Visit Site" : "Book Now"}
                  </Button>
                </VStack>
              </>
            )}
          </HStack>
          {!isLargerThan720 && (
            <HStack mb={4} justifyContent="space-between">
              <HStack>
                <Box fontWeight="bold" lineHeight={"25px"}>
                  {date}
                </Box>
                <Box color="#A8AABC">{time}</Box>
              </HStack>

              <VStack>
                <Text color="#5E6282" fontWeight="bold">
                  {fare.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                </Text>
                <Button
                  variant="link"
                  textColor="#6936F5"
                  rightIcon={<FaArrowRight />}
                  onClick={handleClick}
                >
                  {companyLink ? "Visit Site" : "Book Now"}
                </Button>
              </VStack>
            </HStack>
          )}
        </Box>
      </HStack>
    </Box>
  );
}

const handlePmtTickets = (
  fromTerminalId: string,
  toTerminalId: string,
  date: string
) => {
  let pmtLink = "https://pmt.ng/";
  if (fromTerminalId && toTerminalId) {
    const pmtDate = date.split("/").reverse().join("-");
    pmtLink = `https://pmt.ng/travel/search?terminalFrom=${fromTerminalId}&terminalTo=${toTerminalId}&boardingDate=${pmtDate}&seatQuantity=1`;
  }

  return pmtLink;
};
