import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "react-datepicker/dist/react-datepicker.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
// import Nav from "./components/Nav"
// import Header from "./components/Header"
// import Services from "./components/Services"
// import AppDetails from "./components/AppDetails"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BusApp from "./BusApp";
import Buses from "./components/bus-components/buses";

import './App.css'
const theme = extendTheme({
  fonts: {
    body: `'inter', montserrat`,
  },
});

const queryClient = new QueryClient();
export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      {/* <Box textAlign="center" fontSize="xl" maxWidth="100vw" overflowX="hidden">
     
      <Nav />
      <Header />
      <Services />
      <AppDetails />
    </Box> */}
      <Router>
        <Routes>
          <Route path="/" element={<BusApp />} />
          <Route path="/buses" element={<Buses />} />
        </Routes>
      </Router>
    </ChakraProvider>
  </QueryClientProvider>
);
