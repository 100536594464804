import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import BusNav from "./components/bus-components/bus-nav";
import BusHeader from "./components/bus-components/bus-header";
import BusPopularRoutes from "./components/bus-components/bus-popular-routes";
import BusHowItWorks from "./components/bus-components/bus-how-it-works";
import BusFaq from "./components/bus-components/bus-faq";
import BusFooter from "./components/bus-components/bus-footer";
import { Element, Events, scroller } from "react-scroll";
import { useLocation } from "react-router";
import ReactGA from 'react-ga4';

export default function BusApp() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname+window.location.search });
  }, []);
  
  const state = location.state as { name: string };
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    if (state?.name) {
      scroller.scrollTo(state?.name, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
    // eslint-disable-next-line
  }, [{}, state?.name]);

  return (
    <Box>
      <BusNav />
      <Element name="home">
        <BusHeader />
      </Element>
      <BusPopularRoutes />
      <Element name="how it works">
        <BusHowItWorks />
      </Element>
      <Element name="faq">
        <BusFaq />
      </Element>
      <BusFooter />
    </Box>
  );
}
