import { Box } from "@chakra-ui/react";
import Select from "react-select";

type Option = {
  value: string | number;
  label: string | number;
};

type Props = {
  options: readonly Option[];
  selectedOption?: Option;
  placeholder: string;
  setSelectedOption: () => {};
  field?: any;
  isClearable?: boolean
};

export default function ReactSelect({
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
  isClearable = false,
  ...field
}: Props) {
  return (
    <Box minW={{ base: 200 }}>
      <Select
        // defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        placeholder={placeholder}
        
        styles={{
          container: (styles) => ({
            ...styles,
            height: "inherit",
            flex: 1,
          }),
          control: (styles) => ({
            ...styles,
            height: "inherit",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            border: "2px solid #F4F4F4",
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
            fontSize: "14px",
          }),
          // dropdownIndicator: (styles) => ({
          //   ...styles,
          //   display: "none",
          // }),
          // placeholder: (styles) => ({
          //   ...styles,
          //   color: "black",
          // }),
        }}
        {...field}
        isClearable={isClearable}
      />
    </Box>
  );
}
