import { Box, HStack, Text, Wrap, WrapItem, Button } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import Collapsible from "react-collapsible";
import {
  IoIosArrowDropdownCircle,
  IoIosCloseCircle,
  IoMdCheckmark,
} from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import ReactSelect from "../ReactSelect";
import { createSet } from "../../utils/createSet";
import { partialFilter } from "../../utils/partialFilter";

type Option = {
  value: string;
  label: string;
};

export default function BusFilters({
  busRoutes,
  setBusRoute,
  unfilteredBusRoutes
}: {
  busRoutes: any;
  setBusRoute: any;
  unfilteredBusRoutes: any;
}) {
  const companies = createSet(unfilteredBusRoutes, "company").map((comp) => ({
    value: comp,
    label: (comp as string).toUpperCase(),
  })) as Option[];
  const vehicleType = createSet(unfilteredBusRoutes, "vehicleType").map((comp) => ({
    value: comp,
    label: (comp as string).toUpperCase(),
  })) as Option[];
  const frmTerminal = createSet(unfilteredBusRoutes, "frmTerminal").map((comp) => ({
    value: comp,
    label: (comp as string).toUpperCase(),
  })) as Option[];
  const toTerminal = createSet(unfilteredBusRoutes, "toTerminal").map((comp) => ({
    value: comp,
    label: (comp as string).toUpperCase(),
  })) as Option[];

  const {
    handleSubmit,
    control,
  } = useForm();

  const handleFilter = (data: any) => {

    const filteredBusRoutes = partialFilter(unfilteredBusRoutes, {
      selectedCompany: data.company?.value,
    selectedFromTerminal: data.frmTerminal?.value,
    selectedToTerminal: data.toTerminal?.value,
    selectedVehicleType: data.vehicleType?.value,
    sortOrder : data.priceOrder?.value   
  
    })

    //get all the form values
    //apply the formValues to the filer function and get the array retruned

    //set the array returned to to setBusRoute
    setBusRoute(filteredBusRoutes)
  };

  return (
    <Box
      // width={{ base: "100%" }}
      bgColor="white"
      borderRadius={12}
      py={8}
      px={4}
      mb={4}
      // color="black"
    >
      <Collapsible
        trigger={
          <HStack justifyContent="space-between">
            <Text fontSize={16} fontWeight="bold">
              Filters
            </Text>
            <Icon
              as={IoIosArrowDropdownCircle}
              boxSize={6}
              color="linear-gradient(0deg, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33)), #211C4F "
            />
          </HStack>
        }
        triggerWhenOpen={
          <HStack justifyContent="space-between">
            <Text fontSize={16} fontWeight="bold">
              Filters
            </Text>
            <Icon
              as={IoIosCloseCircle}
              boxSize={6}
              color="linear-gradient(0deg, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33)), #211C4F "
            />
          </HStack>
        }
        overflowWhenOpen="visible"
      >
        <HStack display={{ base: "block", lg: "flex" }}>
          <form onSubmit={handleSubmit(handleFilter)}>
          <Wrap
            display={{ base: "block", lg: "flex" }}
            overflow="visible"
            mb={4}
          >
            <WrapItem display={{ base: "block" }} alignItems="center" flex={1}>
              <SelectLabel>Company:</SelectLabel>
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    options={companies}
                    placeholder=""
                    setSelectedOption={() => ({})}
                    isClearable={true}
                    {...field}
                  />
                )}
              />
            </WrapItem>
            <WrapItem display={{ base: "block" }} alignItems="center" flex={1}>
              <SelectLabel>Vehicle Type:</SelectLabel>
              <Controller
                name="vehicleType"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    options={vehicleType}
                    placeholder=""
                    setSelectedOption={() => ({})}
                    isClearable={true}
                    {...field}
                  />
                )}
              />
            </WrapItem>
            <WrapItem display={{ base: "block" }} alignItems="center" flex={1}>
              <SelectLabel>From Terminal:</SelectLabel>

              <Controller
                name="frmTerminal"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    options={frmTerminal}
                    placeholder=""
                    setSelectedOption={() => ({})}
                    isClearable={true}
                    {...field}
                  />
                )}
              />

            </WrapItem>
            <WrapItem display={{ base: "block" }} alignItems="center" flex={1}>
              <SelectLabel>To Terminal:</SelectLabel>

              <Controller
                name="toTerminal"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    options={toTerminal}
                    placeholder=""
                    setSelectedOption={() => ({})}
                    isClearable={true}
                    {...field}
                  />
                )}
              />

            </WrapItem>
            <WrapItem display={{ base: "block" }} alignItems="center" flex={1}>
              <SelectLabel>Price:</SelectLabel>

              <Controller
                name="priceOrder"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                  options={[
                    { value: "asc", label: "lowest to highest".toUpperCase() },
                    { value: "dsc", label: "highest to lowest".toUpperCase() },
                  ]}
                    placeholder=""
                    setSelectedOption={() => ({})}
                    isClearable={true}
                    {...field}
                  />
                )}
              />

             
            </WrapItem>
            <WrapItem flex={1} alignSelf="end">
              <Button
                rightIcon={<IoMdCheckmark />}
                colorScheme="black"
                variant="outline"
                maxW={400}
                width="100%"
                onClick={handleFilter}
                type="submit"
              >
                Apply Filter
              </Button>
            </WrapItem>
          </Wrap>
          </form>
        </HStack>
      </Collapsible>
    </Box>
  );
}

const SelectLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      fontSize={10}
      fontWeight={600}
      textTransform="uppercase"
      letterSpacing="1px"
      mr={2}
      width="max-content"
    >
      {children}
    </Text>
  );
};
