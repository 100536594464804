import { useEffect, useState } from "react";
import BusNav from "./bus-nav";
import BusSearch from "./bus-search";
import { Box, Button, Center, Spinner } from "@chakra-ui/react";
import BusResults from "./bus-results";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { generateUrlParams } from "../../utils/generateUrlparams";
import getUniqueData from "../../utils/uniqueData";

const BusResultsContainer = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const frmState = urlParams.get("frmState");
  const toState = urlParams.get("toState");
  const date = urlParams.get("date");

  const [busR, setBusRoute] = useState<any[]>([]);

  // Using the hook
  const { data, error, isLoading } = useQuery(
    `buses${generateUrlParams({
      frmState,
      toState,
      date,
      isAvailable: true,
    })}`,
    () =>
      fetch(
        `https://interride-backend.herokuapp.com/api/v1/bus-routes?${generateUrlParams(
          {
            frmState,
            toState,
            // date,
            isAvailable: true,
            pageSize: 700,
          }
        )}`
      ).then((res) => res.json())
  );

  useEffect(() => {
    const uniqueData = getUniqueData(data?.data?.data);

    uniqueData.length > 0 && setBusRoute(uniqueData);
    // eslint-disable-next-line
  }, [data?.data?.data?.length]);

  return (
    <div>
      <BusNav />
      <Box display="flex" justifyContent="center" py={24}>
        <BusSearch frmState={frmState} toState={toState} passedDate={date} />
      </Box>
      {isLoading && (
        <Center mb={8}>
          <Spinner />
        </Center>
      )}
      {error ? (
        <Center mb={8}>
          <Box>Failed to Load Data</Box>
        </Center>
      ) : (
        <>
          {data?.data?.data.length > 0 ? (
            <BusResults
              busRoutes={busR}
              unfilteredBusRoutes={getUniqueData(data?.data?.data)}
              setBusRoute={setBusRoute}
              date={date}
            />
          ) : (
            !isLoading && (
              <Center pt={4} pb={16}>
                {!frmState || !toState ? (
                  <Box>
                    No "from" and "to" State selected, Kindly select a "from"
                    and "to" State and try searching again
                  </Box>
                ) : (
                  <Box textAlign="center">
                    <Box>
                      No Bus Routes found. Kindly let us know where you are going to
                    </Box>
                    <Button
                      bgColor="#6936F5"
                      borderRadius={10}
                      width={{ base: 56, md: 90, lg: 120 }}
                      marginLeft="0 !important"
                      height={12}
                      textColor="white"
                      marginTop={2}
                      onClick={() =>
                        window.open(
                          "https://wa.me/+2347017844062",
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      Contact us
                    </Button>
                  </Box>
                )}
              </Center>
            )
          )}
        </>
      )}
    </div>
  );
};

export default BusResultsContainer;
