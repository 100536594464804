type Item = {
       [key: string]: string;
}

export const createSet = (data: [], property: string) => {
       const set = new Set();
       data.forEach((item : Item) => {
       const lowercaseVal = item[property].toLowerCase(); // Convert to lowercase
         set.add(lowercaseVal);
       });

       return [...set];
}

