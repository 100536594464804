import { Box, Wrap, WrapItem, Center, Spinner, useDisclosure } from "@chakra-ui/react";
import BusRouteCard from "./bus-route-card";
import { useQuery } from "react-query";
import { generateUrlParams } from "../../utils/generateUrlparams";
import BusBookingForm from "./bus-booking-form";
import { useState } from "react";
import { addDays } from "date-fns";

export default function BusPopularRoutes() {
  const { data, error, isLoading } = useQuery(
    `buses${generateUrlParams({
      isPopular: true,
    })}`,
    () =>
      fetch(
        `https://interride-backend.herokuapp.com/api/v1/bus-routes/popularity?${generateUrlParams(
          {
            isPopular: true,
            pageSize: 10,
          }
        )}`
      ).then((res) => res.json())
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [busId, setBusId] = useState<string | null>(null);

  const handleOpen = (id: string) => {
    setBusId(id);
    onOpen();
  };

  const todaysDate = addDays(new Date(), 1).toLocaleDateString();

  return (
    <Box paddingY={{ base: 28, lg: 40 }} bgColor="#F7F7F8">
      <Box display="flex" justifyContent="center" margin="auto">
        <Box width={{ base: "90%", lg: "80%" }}>
          <Box
            as="h3"
            fontSize={{ base: 28, lg: 32 }}
            fontWeight="bold"
            lineHeight={{ base: "20px", lg: "24px" }}
            letterSpacing="-0.02em"
            marginBottom={8}
          >
            Popular routes
          </Box>
          <Box as="p" letterSpacing="-0.03em" marginBottom={8}>
            Book the most affordable bus tickets
          </Box>
          {isLoading && (
            <Center mb={8}>
              <Spinner />
            </Center>
          )}
          {error ?  (
        <Center mb={8}>
        <Box>Failed to Load Popular Routes Data</Box>
        </Center>
      ) : (
        <>
        {data?.data?.data.length > 0 ? (
          <Wrap spacing={[10, 15, 20, 25]} display="flex" justify="center">
          {data?.data?.data.map((route : any) => (
            <WrapItem display="flex">
              <BusRouteCard {...{ ...route }} onOpen={() => handleOpen(route._id)} key={route._id}/>
            </WrapItem>
          ))}
        </Wrap>
        ) : (
          !isLoading && (
            <Center pt={4} pb={16}>
              No popular routes data found!
            </Center>
          )
        )}
      </>
      )}
        </Box>
      </Box>
      <BusBookingForm isOpen={isOpen} onClose={onClose} busId={busId} setBusId={setBusId} date={todaysDate}/>
    </Box>
  );
}
