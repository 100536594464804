export const companyUrls: any = {
       "pmt": "https://pmt.ng/",
       "gigm": "https://gigm.com/",
       "valgee": "https://valgee.com/",
       "efex": "https://efex.com.ng/",
       "guo": "https://guotransport.com/bookingone",
       "libmot": "https://libmot.com/Bus-Result.aspx",
       "abc": "https://www.abctransport.com/",
       "green-stallion" : "https://www.green-stallion.com/",
       "greener line" : "https://greenerlineng.com/",
       "chisco": "https://www.chiscotransport.com.ng/",
       "ekeson": "https://ekesons.com/",
       "ysg": "https://www.ysgtransport.ng/booking",
       "autostar": "https://www.autostar.com.ng/"
}