import { Box, HStack, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import Collapsible from "react-collapsible";
import { IoIosAddCircle, IoIosCloseCircle } from "react-icons/io";

export default function BusFaq() {
  // const faqArr = Array.from(
  //   { length: 5 },
  //   () => "How does Pype benefit my business"
  // );
  return (
    <Box paddingY={40} bgColor="#110B43" color="white">
      <HStack
        maxW={{ base: "80%", lg: "70%" }}
        margin="auto"
        marginBottom={22}
        fontSize={{ base: 24, lg: 34 }}
        fontWeight="bold"
        textAlign="center"
        lineHeight={{ base: "50px", lg: "74px" }}
        letterSpacing="-2px"
      >
        <Text as="h2">Frequently</Text>
        <Text as="h2" color="#F5D50C">
          Asked Questions
        </Text>
      </HStack>

      <Box maxW={{ base: "90%", lg: "70%" }} margin="auto">
        {faqArr.map((faq: any) => (
          <Box
            width={{ base: "100%" }}
            bgColor="#211C4F"
            borderRadius={12}
            py={8}
            px={4}
            mb={4}
          >
            <Collapsible
              trigger={
                <HStack justifyContent="space-between">
                  <Text fontSize={16}>{faq.heading}</Text>
                  <Icon
                    as={IoIosAddCircle}
                    boxSize={6}
                    color="linear-gradient(0deg, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33)), #211C4F "
                  />
                </HStack>
              }
              triggerWhenOpen={
                <HStack justifyContent="space-between">
                  <Text fontSize={18}>{faq.heading}</Text>
                  <Icon
                    as={IoIosCloseCircle}
                    boxSize={6}
                    color="linear-gradient(0deg, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33)), #211C4F "
                  />
                </HStack>
              }
            >
              <Text as="p" paddingTop={4} fontSize={14}>
                {faq.body}
              </Text>
            </Collapsible>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

// {faqArr.map((faq) => (
//   <Box
//     width={{ base: "100%" }}
//     bgColor="#211C4F"
//     borderRadius={12}
//     py={8}
//     px={4}
//     mb={4}
//   >
//     <HStack justifyContent="space-between">
//       <Text>How does Pype benefit my business</Text>
//       <Icon
//         as={FaPlusCircle}
//         boxSize={6}
//         color="linear-gradient(0deg, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33)), #211C4F "
//       />
//     </HStack>
//   </Box>
// ))}

const faqArr = [
  {
    heading: "Which states can I find & Book bus tickets for?",
    body: " You can find & book vehicle (bus & sienna) tickets for all 36 states in Nigeria & the FCT. However we are currently more invested in adding tickets across the following cities - Lagos, Abuja, Enugu, Jos, Anambra, Delta & Rivers. Not withstanding if you would love to book a ticket and can't find it on our platform, kindly contact us and we will help you find a ticket from bus opertaors.",
  },
  {
    heading: "How much do you charge for your services?",
    body: "At the moment we do not charge for our services. We just serve as a bus aggregator platform where you can find bus tickects from different operators all in one place. We will let you know when we start handling bookings!",
    // body: "We charge a service fee of 2% the price of the tickets found on our platform. if the price of the ticket you want to book is #12,000.00, our service fee will be 2% of #12,000.00 which is #240. However if you do not find a ticket on our platform and we have to manually contact bus operators to help you find one, we will charge a flat service fee of #500 so as to enable us cover for the cost of contacting different bus operators.",
  },
  {
    heading: "How do I book the bus tickets found on Grevy?",
    body: "You can click on the 'visit site' button on each bus ticket to go the bus operators website and complete booking. If we start handling booking in the future we will let you know",
  },
  // {
  //   heading:
  //     "If I change my mind after booking or miss my trip do I get a refund?",
  //   body: "It all depends on the refund policy of the bus operator, before finalizing your booking with us and making  payment, we will let you know the refund policy of the bus operator.",
  // },
  {
    heading: "How reliable are the tickets seen on your platform?",
    body: "They are very reliable, we aggregate bus tickets from as many bus operators as possible. While we try to keep this record up to date, we sometimes fall behind and there could be a little discripancy in current price.",
  },
  {
    heading: "What should I do if I still have questions?",
    body: "Kindly reach out to us on Whatsapp or give us a call on 07017844062",
  },
];
