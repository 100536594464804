export const partialFilter = (
  data: any,
  {
    selectedCompany,
    selectedFromTerminal,
    selectedToTerminal,
    selectedVehicleType,
    sortOrder,
  }: any
) => {
  const filteredData = data.filter((item: any) => {
    if (selectedCompany && item.company.toLowerCase() !== selectedCompany) {
      return false;
    }
    if (
      selectedFromTerminal &&
      item.frmTerminal.toLowerCase() !== selectedFromTerminal
    ) {
      return false;
    }
    if (
      selectedToTerminal &&
      item.toTerminal.toLowerCase() !== selectedToTerminal
    ) {
      return false;
    }
    if (
      selectedVehicleType &&
      item.vehicleType.toLowerCase() !== selectedVehicleType
    ) {
      return false;
    }
    return true;
  });

  if (sortOrder) {
    sortOrder === "asc"
      ? filteredData.sort((a: any, b: any) => a.adultFare - b.adultFare)
      : filteredData.sort((a: any, b: any) => b.adultFare - a.adultFare);
  }

  return filteredData;
};
