import { Box, HStack, Text, Image, IconButton } from "@chakra-ui/react";
import PinkStar from "../../assets/PinkStar.png";
import { FaInstagram, FaTwitter, FaSnapchat } from "react-icons/fa";

export default function BusFooter() {
  const handleOpen = (social: "ig" | "tw" | "sn") => {
    const allSocials = {
      ig: "https://www.instagram.com/getgrevy/",
      tw: "https://twitter.com/getgrevy",
      sn: "https://www.snapchat.com/add/getgrevy",
    };

    const socialUrl = allSocials[social];
    window.open(socialUrl, "_blank");
  };
  
  return (
    <Box paddingY={20}>
      <HStack
        maxW={{ base: "80%", lg: "70%" }}
        margin="auto"
        justifyContent="space-between"
      >
        <Box>
          <Text fontWeight="bold" fontSize={16} color="#110B43" mb={2}>
            Contact Us
          </Text>
          <HStack>
            <Text fontSize={16} color="#344054">
              07017844062
            </Text>
            <Text fontWeight="bold">.</Text>
            <Text fontSize={16} color="#344054">
              getgrevy@gmail.com
            </Text>
          </HStack>
          <HStack mt={4}>
            <IconButton
              size="lg"
              aria-label="icon"
              icon={<FaInstagram />}
              variant="solid"
              onClick={() => handleOpen("ig")}
            />

            <IconButton
              size="lg"
              aria-label="icon"
              icon={<FaTwitter />}
              variant="solid"
              onClick={() => handleOpen("tw")}
            />

            <IconButton
              size="lg"
              aria-label="icon"
              icon={<FaSnapchat />}
              variant="solid"
              onClick={() => handleOpen("sn")}
            />
          </HStack>
        </Box>

        <Box>
          <Image src={PinkStar} alt="star" width={{ base: 20, lg: 30 }} />
        </Box>
      </HStack>
    </Box>
  );
}
