import { Box, HStack, Button, Text, Image } from "@chakra-ui/react";
import Sienna from "../../assets/sienna.jpeg";
import Hiace from "../../assets/hiace.jpg"
import { FaArrowRight } from "react-icons/fa";
import { addDays } from "date-fns";
import { companyUrls } from "../../utils/companyUrls";
import ReactGA from 'react-ga4';

type Props = {
  company: string;
  frmState: string;
  toState: string;
  date: string;
  adultFare: number;
  onOpen: () => void;
  frmTerminal: number;
  toTerminal: number;
  vehicleUrl: string;
  vehicleType: string;
};

export default function BusRouteCard({
  company,
  frmState,
  toState,
  date,
  adultFare,
  onOpen,
  vehicleUrl,
  vehicleType
}: Props) {
  const companyLink = companyUrls[company?.toLowerCase()]

  const handleClick = () => {
    ReactGA.event({
      category: "visitSite",
      action: "Click",
      label: `companyLink=${companyLink}&companyName=${company}`,
    });
    companyLink ? window.open(companyLink, "_blank") : onOpen()
  }
  return (
    <Box
      borderRadius={14}
      width={{ base: "90vw", md: 350 }}
      height={56}
      bgColor="white"
      padding={4}
      onClick={handleClick}
      cursor="pointer"
    >
      <HStack mb={4}>
        <Image
          alt="logo"
          src={vehicleUrl || (vehicleType === ("bus" || "bus (luxurious)") ? Hiace : Sienna )}
          // borderRadius="50%"
          width={16}
          // height={50}
        />
        <Box>{company.toUpperCase()}</Box>
      </HStack>
      <Text fontWeight="bold" lineHeight={"25px"}>
        {frmState} &#8594; {toState}
      </Text>
      <Text color="#A8AABC">{date || addDays(new Date(), 1).toLocaleDateString()}</Text>
      <HStack justifyContent="space-between" marginTop={4}>
        <Text color="#5E6282" fontWeight="bold">
          {adultFare.toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
          })}
        </Text>
        <Button variant="link" textColor="#6936F5" rightIcon={<FaArrowRight />} onClick={handleClick}>
        { companyLink ? "Visit Site" : "Book Now" }
        </Button>
      </HStack>
    </Box>
  );
}
