import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  modalTitle?: string;
  children: React.ReactNode;
  modalFooter?: React.ReactNode;
};

function CustomModal({ isOpen, onClose, modalTitle, children, modalFooter }: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay  bg='none'
      backdropFilter='auto'
      backdropInvert='10%'
      backdropBlur='2px'/>
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>

          <ModalFooter>
           {modalFooter}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CustomModal;
